<template>
  <div class="topbar-nav">
  <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <a href="/">
        <img :src="logoSrc" alt="Logo" />
      </a>
<!--    <button @click="toggleSidebar" class="hamburger-btn">&#9776;   fjdshfisd</button>-->
    </v-app-bar>
  </div>

  <v-navigation-drawer class="sidebar-custom" v-model="drawer" :rail="rail" permanent temporary @click="rail = false">

    <v-list class="sidebar-links" density="compact" nav :key="logoutKey">
      <div v-for="adminRoute in adminRoutes" :key="adminRoute.title">
        <v-list-item
        :class="{ 'active-link-custom': adminRoute.active }"
          :prepend-icon="adminRoute.icon"
          :title="adminRoute.title"
          :value="adminRoute.value"
          :to="adminRoute.path"
          @click="toggleActive(adminRoute)"
          :disabled="shouldDisableLink()"

        ></v-list-item>

      </div>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";
import Logo from "@/NewUi/assets/images/Logo.svg";
import { useUserAuth } from "../store/UserAuth";
import {useAdminDashboard} from "../store/AdminDashboard";

export default {
  props: [],

  setup(props, context) {
    let drawer = ref(false);
    let rail = ref(false); // Apparently this is the Equivalent of the mini-variant.
    const router = useRouter();
    const userAuth = useUserAuth();
    const adminDashboard = useAdminDashboard();
    let logoutKey = ref(0);

    const adminRoutes = ref([
      {
        id: 0,
        // icon: "mdi-view-dashboard-outline",
        title: "CHECK EMAIL",
        value: "Dashboard",
        path: "/user/check-email"
      },
      {
        id: 1,
        // icon: "mdi-gift-outline",
        title: "SELECT PACKAGE",
        value: "AddOnServices",
        path: "/user/select-plan"
      },
      {
        id: 2,
        // icon: "mdi-wallet-outline",
        title: "ADD-ON SERVICES",
        value: "A",
        path: "/user/add-on-services"
      },
      {
        id: 3,
        // icon: "mdi-wallet-outline",
        title: "OTHER SERVICES",
        value: "OtherServices",
        path: "/user/other-services"
      },
      {
        id: 4,
        // icon: "mdi-wallet-outline",
        title: "FINALIZE",
        value: "Finalize",
        path: "/user/finalize"
      }

    ]);

    const shouldDisableLink = () => {
      return (!adminDashboard.getUserEmail);
    };


    return {
      drawer,
      rail,
      router,
      userAuth,
      logoutKey,
      adminRoutes,
      shouldDisableLink
    };
  },
  data() {
    return {
      logoSrc: Logo,
    };
  },
  methods: {
    toggleActive(route) {
      // Toggle the 'active' property of the clicked route
      route.active = !route.active;
    },
    // ... existing methods ...
  },
};
</script>

<style lang="css">



.v-navigation-drawer.v-navigation-drawer--left.sidebar-custom {
  top: 71px !important;
  background: transparent;
  border: none;
  z-index: 1 !important;
}
.sidebar-custom.v-navigation-drawer .v-list {
  padding: 35px 35px;
}

.sidebar-custom .v-list-item--nav .v-list-item-title {
  color: var(--navlinks-colo);
  font-family: 'Poppins', sans-serif;
  font-size: var(--sub-heading-size);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sidebar-links .v-list-item__prepend {
  margin: 0px 20px 0px 8px;
}
/* .sidebar-custom .v-list-item:visited .v-list-item-title {
color: brown;
} */

.sidebar-links .v-list-item__prepend > .v-icon ~ .v-list-item__spacer {
  display: none;
}

.sidebar-links .v-list-item--active > .v-list-item__overlay {
  background: transparent;
  opacity: 1;
}

.sidebar-links .v-list-item--nav.v-list-item--active .v-list-item-title {
  position: relative;
 color: var(--text-dark-black) !important;
}
.sidebar-custom .v-list-item--one-line.active-link-custom .v-list-item-title {
  /* Add styles for the active-link-custom class here */
  color: var(--btn-orange-color);
}
.sidebar-custom .v-list-item--density-compact.v-list-item--one-line{
  min-height: 50px;
  cursor: default;
}
/* .sidebar-links .v-list-item--active .v-list-item__prepend > .v-icon {
  color: #ffffff;
} */

.topbar-nav .v-toolbar{
background-color: var(--blue-color);
padding: 5px 45px;

}
.topbar-nav .v-toolbar__content > .v-btn:first-child{
  display: none;
}
/* media  */
@media screen and (min-width: 1920px) {
  .v-navigation-drawer.v-navigation-drawer--left.sidebar-custom {
  top: 75px !important;
}
.sidebar-custom .v-list-item--density-compact.v-list-item--one-line{
  min-height: 53px;
}
}
@media screen and (min-width: 1024px) {
  .v-navigation-drawer.v-navigation-drawer--left.sidebar-custom{
    transform: translateX(0) !important;
  }
}
@media screen and (max-width: 1024px) {
  .v-app-bar .v-app-bar-nav-icon {
  color: white; /* Adjust the color as needed */
}

.v-navigation-drawer.v-navigation-drawer--left.sidebar-custom {
background: var(--main-body-bg);

  /* transform: translateX(-110%) !important; */
}

.sidebar-custom.v-navigation-drawer--left {
  transition: transform 0.3s ease-in-out;
}

.sidebar-custom.v-navigation-drawer--left.nav-open {
  transform: translateX(0);
}

.sidebar-custom.v-navigation-drawer--left.nav-closed {
  transform: translateX(-100%);
}
.topbar-nav .v-toolbar__content > .v-btn:first-child{
  display: block;
  background: var(--btn-orange-color);
  margin-right: 10px;
}
.topbar-nav .v-toolbar{
padding: 5px 10px;
}
}
</style>
